import React, { useContext } from "react";
import { Container, Navbar, Nav, ButtonGroup, Button, NavDropdown} from "react-bootstrap";
import './index.scss';
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../context/UserContext';
const Navigation = () => {
    const {user} = useContext(UserContext)
    const navigate = useNavigate();      
    const entrada = () => {
        if(user.islogin){
            navigate('/entrada')
            //console.log(user.codigo) 
        }    
    }    
    const home = () => {
        if(user.islogin){
            navigate('/home')
            //console.log(user.codigo) 
        }    
    }  
    const clientes = () => {
        if(user.islogin){
            navigate('/Clientes')
            //console.log(user.codigo) 
        }    
    }   
    const veiculo = () => {
        if(user.islogin){
            navigate('/Veiculo')
            //console.log(user.codigo) 
        }    
    }  
    const configuracoes = () => {
        if(user.islogin){
            navigate('/Configuracoes')
            //console.log(user.codigo) 
        }    
    }               
    return (
        <>
            <div>
                <Navbar variant="" expand="lg">
                    <Container className="fw-semibold text-white">
                        <Navbar.Brand onClick={() => home()}>
                        <img
                            src={logo}
                            width="60"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Car Center Pneus Brasil"
                        />            
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link onClick={() => entrada()}>Entrada de Veiculos</Nav.Link>
                            <Nav.Link onClick={() => entrada()}>Controle Oficina</Nav.Link>
                            <Nav.Link onClick={() => entrada()}>Sala de espera</Nav.Link>
                        </Nav>
                        <Nav className="d-flex flex-row-reverse bd-highlight">    
                            <ButtonGroup aria-label="Basic example" >
                                <Button variant="">
                                    <NavDropdown title="Acessos">
                                        <NavDropdown.Item onClick={() => clientes()}>Clientes</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => veiculo()}>Veiculos</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => configuracoes()}>Configurações</NavDropdown.Item>
                                        <NavDropdown.Item href="/">Sair</NavDropdown.Item>
                                    </NavDropdown>
                                </Button>
                            </ButtonGroup>
                        </Nav>    
                        </Navbar.Collapse>
                        
                    </Container>
                </Navbar>    
            </div>
        </>
    ) 
}

export default Navigation;