import React, { useContext } from 'react'
import {Routes as Router, Route,Navigate, Outlet} from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Navbar from "../Components/Navbar";
import Entrada from "../views/Entrada";
import Novaentrada from "../views/Novaentrada";
import Clientes from "../Components/Clientes";
import Login from "../Components/Login";
import Veiculo from "../Components/Veiculo"
import Configuracoes from '../Components/Configuracao';
import Editarentrada from '../views/Editarentrada';
import Camera from '../Components/Camera/';

const PrivateRoutes = () => {
    const { user } = useContext(UserContext)
    //console.log(user.name)
    if(!user.islogin) {
        return <Navigate to={'/'} replace />
    }
    return <Outlet />
  }
const Rotas = () => {
   // const { user } = useContext(UserContext)
    return(
            <Router>
                <Route path="/" element={<Login /> } />                
                <Route element={<PrivateRoutes />}>
                    <Route path="/home" element={<Navbar /> } />
                    <Route path="/entrada" element={ <Entrada />} />
                    <Route path="/novaentrada" element={ <Novaentrada />} />
                    <Route path="/editarentrada" element={ <Editarentrada />} />
                    <Route path="/Clientes" element={ <Clientes />} />   
                    <Route path="/Veiculo" element={ <Veiculo />} />  
                    <Route path="/Configuracoes" element={ <Configuracoes />} /> 
                    <Route path="/Camera" element={ <Camera />} /> 
                </Route>
            </Router>
    );
}

export default Rotas;