import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
//import { FaQrcode } from 'react-icons/fa';
//import { MdDeleteForever } from 'react-icons/md';
import api from '../../Components/Api/Api';
import { UserContext } from '../../context/UserContext';
import "./listaentradas.scss";
import Spinner from "../../Components/Spinner";
import { Link} from 'react-router-dom';
const Lista = () => {
  const {user} = useContext(UserContext) 
  const [atualizar, setAtualizar] = useState(false)  
  const [ShowLoading, setShowLoading] = useState(false)  
  const [orcamentos, setOrcamentos] = useState([])
  function getentradas () {
    setTimeout(() => {
    setShowLoading(true)
    api.get('Entradas?page=0&perpage=10&filial=01&vendedor='+user.codigo)
    .then(response => {
          //console.log(response.data.Entradas)
          if (response.data.Entradas.length > 0 ){   
              console.log(response.data.Entradas[0]['Num'])  
              setOrcamentos(response.data.Entradas)
              setAtualizar(true) 
              setShowLoading(false)  
              //setInfoveuculo('Placa: ' + placa + ' Modelo: ' + response.data.Dadosveiculo[0]['modelo'] + ' Cor: ' + response.data.Dadosveiculo[0]['cor'])                     
          }else{
              //setInfoveuculo('Placa não cadastrada!')
              setShowLoading(false)
              setAtualizar(true)
          }                                   
    });
  }, 10 );        
  };

  useEffect(() => {
    if(!atualizar){
      getentradas()
    }
  })   
    return (
      <div className='mt-4'>    
        <Table striped bordered hover variant="light" responsive className="tabela">
          <thead>
              <tr className='col-title'>
              <th>Id</th>
              <th>Cliente</th>
              <th>Loja</th>
              <th>Nome</th>
              <th>Orçamento</th>
              <th>Placa</th>   
              <th>Editar</th> 
              <th>Foto</th>            
              </tr>
          </thead> 
          <tbody> 
          {orcamentos.map((list: any) => (           
            <tr key={list.id}>
              <td>{list.id}</td>
              <td>{list.cliente}</td>
              <td>{list.loja}</td>
              <td>{list.nomecliente}</td>
              <td>{list.Num}</td>
              <td>{list.placa}</td>
              {/* <td><button onClick={}>Editar</button></td> */}
              <td><Link to='/editarentrada' state={list.Num}>Editar</Link></td>
              <td><Link to='/Camera' state={list.Num}>Foto</Link></td>
            </tr>
          ))}                         
          </tbody>
        </Table>       
        <Spinner isLoading={ShowLoading} />                       
      </div>
    );
};

export default Lista;