import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Container, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import api from '../../Components/Api/Api';
import "./index.scss";

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { HiOutlineXMark  } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../Components/Spinner";
import Configjson from "../../Components/Configuracao/config.json"
import { UserContext } from '../../context/UserContext';

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });
const Editarentrada = () => {
    const location = useLocation();
    const orcamento = location.state;
    const {user} = useContext(UserContext)
    const navigate = useNavigate(); 
    const [placa, setPlaca] = useState('')
    const [cgc,setCGC] = useState('')
    const [codigo, setCodigo] = useState('')
    const [loja, setLoja] = useState('')
    const [nome, setNome] = useState('')
    const [infoveiculo,setInfoveuculo] = useState('')
    const [showmodal, setShowmodal] = useState(false)
    const [ShowLoading, setShowLoading] = useState(false)
    const [p1, setP1] = useState([0]);
    const [p2, setP2] = useState([0]);
    const [obs, setObs] = useState('')
    const vendedor = user.codigo
    const [idp1,setIdp1] = useState('')
    const [idp2,setIdp2] = useState('')
    const [getorcamento, setGetOrcamento] = useState(true)
    interface Itens {
        id: number;
        produto: string;
        prodescricao: string;
        quantidade: number;
        preco: number;
      }     
    //const itens: Itens[] = [];
    const [_pro, set_Pro] = useState<Itens[]>([]);
    const gerOrcamento = () =>{
        api.get('EditarOrcamento?filial=01&consulta='+orcamento)
        .then(response => {
            if (response.data.Orcamento.length > 0 ){ 
                if(getorcamento){
                    setGetOrcamento(false);
                    setCodigo(response.data.Orcamento[0]['codigo']);
                    setLoja(response.data.Orcamento[0]['loja']);  
                    setNome(response.data.Orcamento[0]['nome']);  
                    setIdp1(response.data.Orcamento[0]['p1']); 
                    setIdp2(response.data.Orcamento[0]['p2']); 
                    setPlaca(response.data.Orcamento[0]['placa']); 
                    setObs(response.data.Orcamento[0]['obs']); 
                    setInfoveuculo('Placa: ' + response.data.Orcamento[0]['placa'] ); //+ ' Modelo: ' + response.data.Dadoscliente[0]['modelo'] + ' Cor: ' + response.data.Dadoscliente[0]['cor'])               
                    if (_pro.length < response.data.Orcamento.length){
                        for (let i = 0; i < response.data.Orcamento.length; i++) {
                            //itens.push({ id: Number(response.data.Orcamento[i]['id']), produto: response.data.Orcamento[i]['produto'], prodescricao: response.data.Orcamento[i]['descricao'], quantidade: response.data.Orcamento[i]['quantidade'], preco: response.data.Orcamento[i]['vlunitario'] });
                            _pro.push({ id: Number(response.data.Orcamento[i]['id']), produto: response.data.Orcamento[i]['produto'], prodescricao: response.data.Orcamento[i]['descricao'], quantidade: response.data.Orcamento[i]['quantidade'], preco: response.data.Orcamento[i]['vlunitario'] }) 
                        } 
                    } 
                    set_Pro(_pro)                  
                }  
            }else{
                setCodigo('Cliente não cadastrado!')
                setLoja('')  
                setNome('')
                setPlaca('');
                setInfoveuculo('Placa não cadastrada!')                
            }                                   
        });                               
    }
    
    const getplaca = () => {
        api.get('Dadosveiculo?filial=01&consulta='+placa)
        .then(response => {
            if (response.data.Dadosveiculo.length > 0 ){        
                setInfoveuculo('Placa: ' + placa + ' Modelo: ' + response.data.Dadosveiculo[0]['modelo'] + ' Cor: ' + response.data.Dadosveiculo[0]['cor'])                     
            }else{
                setInfoveuculo('Placa não cadastrada!')
            }                                   
        });        
    }
    const handlingplaca = (e :any) => {
        setPlaca(e.target.value);
    }
   
    useEffect(() => {            
        const el = document.querySelectorAll("div[id=divmodal]");      
          if (showmodal === true){
            el[0].setAttribute('style', 'display:block');
          }else{
            el[0].setAttribute('style', 'display:none');
          }

          if(p1.length === 1){
                let p_1 = [0]
              for (let i = 1; i <= Configjson.data[0].P1; i++) {
                p_1.push(i)
              } 
              setP1(p_1)
          }  
          if(p2.length === 1){
            let p_2 = [0]
            for (let i = 1; i <= Configjson.data[0].P2; i++) {
              p_2.push(i)
            } 
            setP2(p_2)
        } 
        if(getorcamento){
            gerOrcamento()
        }        
    });  
    //Busca produtos inicio          
        const columns = [
            { name: "produto", width: '10%', options: { filterOptions: { fullWidth: true } }  },
            "descricao",
          ];
          const [rowsSelected, setrowsSelected] = useState([])
        const options = {
            textLabels: {
                body: {
                  noMatch: '',
                }
              },             
            search: true,
            viewColumns: true,
            filter: true,
            download: false,
            print: false,
            reponsive: "simple",
            tableBodyHeight: "400px",
            rowsSelected: rowsSelected,   
            selectableRowsHeader: false,  
            onTableChange: (action :any, state :any) => {            
            },
            onRowSelectionChange: (rowsSelectedData :any, allRows :any, rowsSelected :any) => {
              setrowsSelected(rowsSelected);
              for (let i = 0; i < rowsSelected.length; i++) {
                _pro.push({ id: i, produto: data[rowsSelected[i]]['produto'], prodescricao: data[rowsSelected[i]]['descricao'], quantidade: 0, preco: data[rowsSelected[i]]['preco'] })
              }
            },
            // onCellClick: (colData: any, cellMeta: { colIndex: number, rowIndex: number, dataIndex: number }) => {
            //     console.log(cellMeta)
            // }, 
            isRowSelectable: (dataIndex: any, selectedRows: any) => {

                if (selectedRows.data.length > 0 && selectedRows.data.filter((d: any) => d.dataIndex === dataIndex).length === 0) return false;
                //prevents selection of row with title "Attorney"
                return data[dataIndex][1] !== "000000";
            },  
   
        };
          const [data,setData] = useState([]) 
        
          const getbusca = (e :any) => {
            api.get('Dadosprodutos?consulta='+e.target.value)
            .then(response => {
                if(response.data.Dadosprodutos.length > 0){
                    setData(response.data.Dadosprodutos)                                    
                }else{
                    setData([])
                }
            });         
        }
        const handlingincluir = () => {
            setShowmodal(false)
            setrowsSelected([])
            //setData([])
        }
        const handlingexcluir = (e: any,index: any) => {
            let it = [..._pro]; 
            let answer = window.confirm("Deseja realmente excluir esse item?");           
            //const el = document.querySelectorAll('tr'); 
            if (answer) {
                //el[index+1].setAttribute('style', 'visibility: hidden;'); 
                it.splice(index,1);
                set_Pro(it);                 
                console.log('Item excluido!.');
              } else {
                console.log('item não excluido!');
              }                      

        }
        const changeItems = (e: any, index: any) => {        
            let it = [..._pro];   
            it[index].quantidade = e.target.value;
            set_Pro(it);
          }  
        const geraorcamento = () => { 
            if(_pro.length === 0){
                setShowLoading(false); 
                return(
                    alert("falta produtos no orçamento!")
                )
            } 
            if(codigo === ''){
                setShowLoading(false); 
                return(
                    alert("Por favor, selecionar um cliente!")
                )
            }  
            if(placa === ''){
                setShowLoading(false); 
                return(
                    alert("Por favor, informar a palca do veiculo!")
                )
            }                       
            let vltotal = 0       
            const url = 'Pedidoloja';
            const headers = {
                'Content-Type': 'application/json'
              }            
            let payload = ''
            payload += '{';
            payload += '"nOpx": 4,';
            payload += '"LQ_FILIAL": "01",';
            payload += '"LQ_NUM": "'+orcamento+'",';
            payload += '"LQ_CLIENTE": "'+codigo+'",';
            payload += '"LQ_LOJA": "'+loja+'",';    
            payload += '"LQ_VEND": "'+vendedor+'",';         
            payload += '"LQ_PLACAVE": "'+placa+'",';
            payload += '"LQ_OBS": "'+obs+'",';
            payload += '"LQ_P1": "'+idp1+'",';
            payload += '"LQ_P2": "'+idp2+'",';
            payload += '"Items": [';
            for (let i = 0; i < _pro.length; i++) {
                if(_pro[i]['quantidade'] === 0){
                    setShowLoading(false); 
                    return(
                        alert("Por favor preencher a quantidade do produto! " + _pro[i]['produto'])
                    )
                }
                payload += '{';
                payload += '"LR_FILIAL": "01",';
                payload += '"LR_PRODUTO": "'+_pro[i]['produto']+'",';
                payload += '"LR_ITEM": "'+addZeroes((i+1), 2)+'",';
                payload += '"LR_DESCRI": "'+_pro[i]['prodescricao']+'",';
                payload += '"LR_QUANT": '+_pro[i]['quantidade']+',';
                payload += '"LR_PRCTAB": '+_pro[i]['preco']+',';
                payload += '"LR_VRUNIT": '+_pro[i]['preco']+',';
                let prtotal = _pro[i]['quantidade'] * _pro[i]['preco'] 
                payload += '"LR_VLRITEM": '+prtotal+',';
                payload += '"LR_PRTABD": '+_pro[i]['preco']+',';
                payload += '"LR_LOCAL":"01",';
                payload += '"LR_TES": "501",';
                payload += '"LR_ENTREGA":"2"';
                vltotal = vltotal + prtotal
                if(_pro.length > 1) {
                    if ((i + 1) < _pro.length){
                        payload += '},';
                    }
                } 
            }
            payload += '}'; 
            payload += '],'; 
            payload += '"Parcelas": ['; 
            payload += '{';
            payload += '"L4_DATA": "31/07/2024",';
            payload += '"L4_VALOR": '+vltotal+',';
            //payload += '"L4_VALOR": 2863,';
            payload += '"L4_FORMA": "R$"';
            payload += '}';
            payload += ']';
            payload += '}';
            const data = payload 
            api.post(url,data, {"headers" : headers})
            .then(function (response) {
                if(response.data["info"] === 'Erro'){
                    alert("Orçamento não alterado, numero: "+ response.data["codigo"] )  
                    console.log("Orçamento não alterado, numero: ", response.data["codigo"] ) 
                    setShowLoading(false);  
                    navigate("/entrada");            
                }
                if(response.data["info"] === 'criado'){
                    alert("Orçamento alterado, numero: "+ response.data["codigo"] )  
                    console.log("Orçamento alterado, numero: ", response.data["codigo"] ) 
                    navigate("/entrada");   
                    set_Pro([])
                    setShowLoading(false);             
                }

            })
            .catch(function (error) {
                console.error(error);
                alert(error)
                setShowLoading(false); 
            });             
        }
        const modalclose = () => {
            setShowmodal(false)
            setData([])
            setrowsSelected([])            
        }  
        const changeobs = (e: any) =>{
            setObs(e.target.value)
        } 
        const chageselectmecanico = (e: any) => {
            if (e.target.value !== 'P10') {
                setIdp1(e.target.value)
            }          
        } 
        const chageselectalinhamento = (e: any) => {
            if (e.target.value !== 'P20') {        
                setIdp2(e.target.value)
            }    
        }             
    return(
        <>
        <Navbar/>
        <Container>
            <Form>
                <div>
                    <span className="d-flex justify-content-center fs-3 text-primary">Orçamento {orcamento}</span>
                </div>                
                <div className="row celular">                    
                    <Form.Group className="col-8 form-cel">
                        <Form.Label>Cliente</Form.Label>
                        <p className="m-0">{codigo} - {loja} - {nome} </p>  
                    </Form.Group>
                </div>                                                 
                <div className="row celular">
                    <Form.Group className="mb-1 me-4 col-2">
                        <Form.Label>Placa</Form.Label>
                        <input type="text" id="placa" name="placa" className="input-cel" onChange={ (e) => { handlingplaca(e) }} onBlur={ getplaca } placeholder="Digite a placa" />
                    </Form.Group>
                    <Form.Group className="col-1 form-cel">
                        <Button className="btn btn-info btn-sm" variant="success" onClick={ getplaca }>Buscar</Button>
                    </Form.Group>
                    <Form.Group className="col-8 form-cel">
                        <p className="m-0">{infoveiculo} </p>  
                    </Form.Group>
                </div> 
                <div className="row celular">
                    <Form.Group className="me-4 col-2">
                        <Form.Label >Prisma</Form.Label>                     
                    </Form.Group>                    
                    <Form.Group className="">
                        <Form.Label className="me-2" >Mecanico</Form.Label> 
                        <select onChange={(e) => chageselectmecanico(e)}>
                            <option key={99} value={'P1'+Number(idp1.substring(2))}>{addZeroes(Number(idp1.substring(2)),3)}</option>
                            {p1.map((i: any, k: any) => 
                               <option key={k} value={'P1'+i}>{addZeroes(i,3)}</option> 
                            )}
                        </select>  
                        <Form.Label className="me-2 ms-4" >Alinhamento/Balanceamento</Form.Label> 
                        <select onChange={(e) => chageselectalinhamento(e)}>
                            <option key={99} value={'P2'+Number(idp2.substring(2))}>{addZeroes(Number(idp2.substring(2)),3)}</option>
                            {p2.map((i: any, k: any) => 
                               <option key={k} value={'P2'+i}>{addZeroes(i,3)}</option> 
                            )}
                        </select>                                                
                    </Form.Group>
                </div>
                <div className="row celular">
                    <Form.Group>
                        <Form.Label className="me-2" >Observações: </Form.Label> 
                        <input type="text" className="w-100" value={obs} onChange={(obs) => changeobs(obs)}/>
                    </Form.Group>
                </div>                  
                    <Row>
                        <Col className="mt-lg-3 mt-sm-3">
                            <Tabs
                            defaultActiveKey="latest"
                            transition={false}
                            id="collum-3"
                            className="mb-3"
                            justify 
                            >
                                <Tab eventKey="latest" title="Produtos" >
                                    <Table striped borderless hover>
                                        <tbody>
                                            <tr className="fw-bold">
                                            <td>Produto</td>
                                            <td>Descrição</td>
                                            <td>Quantidade</td>
                                            <td>preço</td>
                                            <td>Excluir</td>
                                            </tr>  
                                            {_pro.map((iten: any,i: any) => (
                                                <tr key={i} id={i} className={i}>
                                                <td>{iten.produto}</td>
                                                <td>{iten.prodescricao}</td>
                                                <td className="fw-bold" > 
                                                <input type="number" value={iten.quantidade} onChange={ (e) => { changeItems(e,i)}} placeholder="Quantidade" required/>
                                                </td>
                                                <td>{Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(iten.preco)}</td>
                                                <td>                        
                                                <Button className="" size="sm" variant="outline-danger " onClick={(e) => {handlingexcluir(e,i)}} >
                                                    <HiOutlineXMark  />                                                  
                                                </Button>                                                    
                                                </td>
                                                </tr>
                                                // onClick={(e) => {handlingQuantidade(e,i)}}
                                            ))}
                                        </tbody>
                                    </Table>                                            
                                </Tab>
                            </Tabs>                    
                        </Col>                        
                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-primary" variant="success" onClick={() => setShowmodal(true)}>Inclui</Button>
                        </Col>                        
                    </Row>
                    <Row>
                        <div id="divmodal" className="modal show" >
                            <Modal
                                show={showmodal}
                                onHide={() => (modalclose())}
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Produtos</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="mb-2">
                                        <CacheProvider value={muiCache}>
                                            <ThemeProvider theme={createTheme()}>
                                                <Row>
                                                    <Col className="label-buscaproduto"><label>Busca produtos: </label></Col>
                                                    <Col><input className="mb-3 mt-2 w-100" onBlur={(e) => {getbusca(e)}}></input></Col>
                                                </Row>
                                                <MUIDataTable
                                                title={""}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                                />
                                            </ThemeProvider>
                                        </CacheProvider>                                      
                                    </Row>
                                    <Row className="mt-4">
                                        <Button className=" ms-3 w-25 btn btn-primary" variant="success" onClick={() => handlingincluir()} >Incluir</Button>
                                    </Row>
                                </Modal.Body>
                            </Modal> 
                        </div>
                    </Row>
                <div className="mt-4 d-flex justify-content-start ">
                    <Button variant="primary" onClick={() => (setShowLoading(true),geraorcamento())}>
                        Grava Alteração
                    </Button>
                </div>
                {/* <div>
                    {loading ? <img src={spinnerimg} alt="Loading" style={{ width: 250, flex: 1, justifyContent: "center"} }></img> : false}
                </div> */}
                <Spinner isLoading={ShowLoading} />
            </Form>
        </Container>     
        </>
    )
    
};

function addZeroes(num :number, len: number) {
    var numberWithZeroes = String(num);
    var counter = numberWithZeroes.length;     
    while(counter < len) { 
      numberWithZeroes = "0" + numberWithZeroes;    
    counter++;  
    }
  
    return numberWithZeroes;
}
export default Editarentrada;