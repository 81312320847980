import {ClipLoader} from 'react-spinners';
import "./index.scss";
type Props = {
    isLoading?: boolean;
};

const Spinner = ({isLoading = true}: Props) => {
    return(
        <>
            <div id="loading-spinner">
                <ClipLoader
                    color="#1115f7"
                    loading={isLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                 />   
            </div>
        </>
    )
};

export default Spinner;