import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Navigation from "../Navbar";
import api from "../Api/Api";
import Spinner from "../../Components/Spinner";
import { MdAddAPhoto,MdOutlinePhotoCamera } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import './index.scss';

const WebcamCapture = () => {
  const location = useLocation();
  const orcamento = location.state;
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showinit, setShowinit] = useState(true)
  const [showcamera, setShowcamera] = useState(false)
  const [showfoto, setShowfoto] = useState(false)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [ShowLoading, setShowLoading] = useState(false)
  const [getQuantfiles, setGetquantfiles] = useState(false)
  const [quantfiles, setQuantfiles] = useState(0)

  async function QuantFiles(Orcamento: string) {
    setTimeout(() => {
      setShowLoading(true)
      setGetquantfiles(true)
      api.get('Getimagens?Orcamento='+Orcamento)
      .then(response => {
            //console.log(response.data.Fotos)
            if (response.data.Fotos.length > 0 ){   
                //console.log(response.data.Fotos[0]['arqs'])  
                setQuantfiles(response.data.Fotos[0]['arqs'])
                setShowLoading(false)           
            }else{
                setShowLoading(false)
            }                                   
      });
    }, 10 );
  }
  async function saveFile(imagem: any, Orcamento: string) {
    setShowLoading(true)
    let qunatf = Number(quantfiles) + 1
    const url = 'DOWNLOAD_FILE?Orcamento='+Orcamento+'&idfoto='+qunatf;
    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "User-Agente": "PneusBrasil",
        "Accept": "*/*",
      } 
      //const decode = (str: string):string => Buffer.from(str, 'base64').toString('binary')
      //console.log(imagem.toString('binary'))

      //const data = {imagem.toString('binary')}  
      api.post(url,imagem.toString('binary'), {"headers" : headers})
      .then(function (response) {
          //console.log(response.data['info']);
          if(response.data['info'] === 'erro'){
              setShowLoading(false)
              alert('imagem não gravada, foto: '+ response.data['codigo'])
          }  
          if(response.data['info'] === 'criado'){ 
              setShowLoading(false)
              alert('Imagem gravada com sucesso, foto: '+ response.data['codigo'] )
              setCapturedImage(null); // Reset captured image
              setShowcamera(false)
              setShowfoto(false)
              setShowinit(true)
              QuantFiles(orcamento)
              //setCadok(true)               
          }                       
      })
      .catch(function (error) {
          console.error(error);
          setShowLoading(false)
          alert('Alert: Erro de conexão com servidor! ')
      });     
    //console.log('imagem',imagem)
  }  

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment'
        } 
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setShowcamera(true)
      setShowinit(false)
      setMediaStream(stream);
    } catch (error) {
      console.error("Error accessing webcam", error);
    }
  };

  // Function to stop the webcam
  const stopWebcam = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      // Set canvas dimensions to match video stream
      if (context && video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Draw video frame onto canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get image data URL from canvas
        const imageDataUrl = canvas.toDataURL("image/jpeg");
        // Set the captured image
        setCapturedImage(imageDataUrl);
        setShowcamera(false)
        setShowfoto(true)
        // Stop the webcam
        stopWebcam();

        // You can do something with the captured image here, like save it to state or send it to a server
      }
    }
  };

  // Function to reset state (clear media stream and refs)
  const resetState = () => {
    startWebcam()
    setCapturedImage(null); // Reset captured image
    setShowfoto(false)
    setShowcamera(true)
  };
  const voltainit = () => {
    stopWebcam();
    setCapturedImage(null); // Reset captured image
    setShowfoto(false);
    setShowcamera(false);
    setShowinit(true);
  }
  const enviarfoto = () =>{
    saveFile(capturedImage, orcamento)
  }
  useEffect(() => {    
    const init = document.querySelectorAll("div[id=divinit]");          
    const camera = document.querySelectorAll("div[id=divcamera]");   
    const foto = document.querySelectorAll("div[id=divfoto]");     
      if (showcamera === true){
        camera[0].setAttribute('style', 'display:block');
      }else{
        camera[0].setAttribute('style', 'display:none');
      } 
      if (showinit === true){
        init[0].setAttribute('style', 'display:block');
      }else{
        init[0].setAttribute('style', 'display:none');
      }       
      if (showfoto === true){
        foto[0].setAttribute('style', 'display:block');
      }else{
        foto[0].setAttribute('style', 'display:none');
      } 
      if(!getQuantfiles){
        QuantFiles(orcamento)
      }                 
  });   
  return (
    <>
    <Navigation />    
    <Container>
    <div className="mt-2">    
      <div id="divinit" className="init-page">
        <div>
          <label>Orçamento: {orcamento}</label>
        </div>
        <div>
          <label>Quantidade de fotos: {quantfiles}</label>
        </div>        
        <div>
          <button onClick={startWebcam}>Fotos <MdAddAPhoto size={50} /></button>
        </div>
      </div> 

      <div id="divcamera">
        <div>
          <video className="w-100" ref={videoRef} autoPlay muted />
        </div>
        <div>
          <button className="me-4" onClick={captureImage}><MdOutlinePhotoCamera size={50} /> Foto </button>
          <button onClick={voltainit}><RiArrowGoBackLine size={50} /> Voltar </button>
        </div>
      </div>  

      <div id="divfoto" >
      {capturedImage ? (
          <div>
            <img src={capturedImage} className="w-100" />
            <button className="mt-2 me-5" onClick={resetState}><RiArrowGoBackLine size={50} /> Voltar </button>
            <button className="mt-2" onClick={enviarfoto}><BsFillSendArrowUpFill  size={50} /> Enviar </button>
          </div>
        ) : (
          <>
            <canvas ref={canvasRef} />
          </>
        )}
      </div>     
    </div>  
    <Spinner isLoading={ShowLoading} />
    </Container>
    </>
  );
};

export default WebcamCapture;