import { Container } from "react-bootstrap";
import Navigation from "../Navbar";
const Configuracoes = () => {

    return(
        <>
            <Navigation />
            <Container>
                <div>
                    <span className="d-flex justify-content-center fs-3 text-primary">Configurações</span>
                </div>                 
            </Container>
        </>
    )
}

export default Configuracoes;
