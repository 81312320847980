import Routes from "./routes";
import { BrowserRouter } from 'react-router-dom';
import UserProvider from './context/UserContext';
import './App.scss';

const App:React.FC = () => { 
  return (
      <BrowserRouter>
        <UserProvider>
          <div className="App">
            <Routes />
          </div>  
        </UserProvider>
      </BrowserRouter>
  )
}

export default App;
