import { Button, Container } from "react-bootstrap";
import api from "../Api/Api";
import Navigation from "../Navbar";
import { useEffect, useState } from "react";
import TextInput from '../MaskInput/MaskInput';
import './index.scss';
import { useNavigate } from "react-router-dom";
import Spinner from "../../Components/Spinner";
const Clientes = () => {
    const navigate = useNavigate(); 
    const [nome, setNome] = useState('');
    const [cgc, setCGC] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [cep, setCep] = useState('');
    const [endereco, setEndereco] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [cadok, setCadok] = useState(false)
    const [ShowLoading, setShowLoading] = useState(false)
    const siglauf = ['','AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']
    const handleCadastro = (e: any) => {
        if(e.target.id === 'nome'){
            setNome(e.target.value)
        }else if (e.target.id === 'cgc'){
            setCGC(e.target.value)
        }else if (e.target.id === 'cep'){
            setCep(e.target.value)
        }else if (e.target.id === 'estado'){
            setEstado(e.target.value)
        }else if (e.target.id === 'cidade'){
            setCidade(e.target.value)
        }else if (e.target.id === 'bairro'){
            setBairro(e.target.value)
        }else if (e.target.id === 'endereco'){
            setEndereco(e.target.value)
        }else if (e.target.id === 'telefone'){
            setTelefone(e.target.value)
        }else if (e.target.id === 'email'){
            setEmail(e.target.value)
        }
        //console.log(cgc)
    }

    const handlingenviar = (e: any) =>{
        setShowLoading(true)
        if((nome && cgc && cep && estado && cidade && bairro && endereco) === ''){
            alert('Preencher os campos obrigatorios!')
            return
        }        
        const url = 'Cliente';
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "User-Agente": "PneusBrasil",
            "Accept": "*/*",
          }         
        const data = {
            'Empresa':"01",
            'Filial':"01",
            'A1_NOME': nome,
            'A1_NREDUZ': nome.substring(0,16),
            'A1_CGC': cgc,
            'A1_EST': estado,
            'A1_CONTRIB': "2",
            'A1_BAIRRO': bairro,
            'A1_CEP': cep,
            'A1_END': endereco,
            'A1_MUN': cidade,
            'A1_TEL': telefone,
            'A1_EMAIL': email,
            'A1_SATIV1': "000001"            
        }
        api.post(url,data, {"headers" : headers})
        .then(function (response) {
            //console.log(response.data['info']);
            if(response.data['info'] === 'ja existe'){
                setShowLoading(false)
                alert('Cliente já cadastrado com esse CPF ou CNPJ, Código: '+ response.data['codigo'])
            }
            if(response.data['info'] === 'cidade'){ 
                setShowLoading(false)
                alert('Alert: Cidade informada não foi encotrada na base do IBGE ')
            }   
            if(response.data['info'] === 'criado'){ 
                setShowLoading(false)
                alert('Cliente cadastrado com sucesso, código: '+ response.data['codigo'] + ' loja: 01' )
                setCadok(true)               
            }                       
        })
        .catch(function (error) {
            console.error(error);
            setShowLoading(false)
            alert('Alert: Erro de conexão com servidor! ')
        });                        
    }
    useEffect(() => {
        if(cadok) { 
            navigate('/novaentrada')
        }
                       
    })
    return (
        <>
        <Navigation />
        <Container>
            <div>
                <span className="d-flex justify-content-center fs-3 text-primary">Cadastro de clientes</span>
            </div>
            <div className="input-group mt-2 mb-2">
                <span className="input-group-text me-2" id="basic-addon1">Nome</span><span className="me-2 text-danger">*</span>
                <input type="text" id="nome" name="nome" className="w-75 " onBlur={ (e) => { handleCadastro(e) }} />
            </div>
            <div className="input-group mt-2 mb-2">
                <span className="input-group-text me-2" id="basic-addon1">CPF/CNPJ</span><span className="me-2 text-danger">*</span>
                <TextInput type="text" id="cgc" name="cgc" className="w-25 " onBlur={ (e) => { handleCadastro(e) }} maskType="cpf" />
            </div> 
            <div className="input-group mt-2">
                    <span className="input-group-text me-2" id="basic-addon1">CEP</span><span className="me-2 text-danger">*</span>
                    <TextInput type="text" id="cep" name="cep" onBlur={ (e) => { handleCadastro(e) }} maskType="cep"  />
                </div>             
            <div className="row">                     
                <div className="input-group mt-2 mb-2 col estado">
                    <span className="input-group-text me-2" id="basic-addon1">Estado</span><span className="me-2 text-danger">*</span>
                    <select id="estado" onBlur={(e) => handleCadastro(e)}>
                            {siglauf.map((i: any, k: any) => 
                               <option key={k} value={i}>{i}</option> 
                            )}
                    </select>
                </div>   
                <div className="input-group mt-2 mb-2 col-sm cidade">
                    <span className="input-group-text me-2" id="basic-addon1">Cidade</span><span className="me-2 text-danger">*</span>
                    <input type="text" id="cidade" name="cidade" className="w-75" onBlur={ (e) => { handleCadastro(e) }}  />
                </div>                         
            </div> 
            <div className="input-group mt-2 mb-2 col-sm">
                    <span className="input-group-text me-2" id="basic-addon1">Bairro</span><span className="me-2 text-danger">*</span>
                    <input type="text" id="bairro" name="bairro" className="w-75" onBlur={ (e) => { handleCadastro(e) }}  />
            </div>    
            <div className="input-group mt-2 col-sm">
                    <span className="input-group-text me-2" id="basic-addon1">Endereço</span><span className="me-2 text-danger">*</span>
                    <input type="text" id="endereco" name="endereco" className="w-75" onBlur={ (e) => { handleCadastro(e) }}  />
            </div> 
            <div className="row">
                <div className="input-group mt-2 mb-2 col telefone">
                        <span className="input-group-text me-2" id="basic-addon1">Fone</span>
                        <TextInput type="text" id="telefone" name="telefone" className=" " onBlur={ (e) => { handleCadastro(e) }} maskType="phone" />
                </div>                    
                <div className="input-group mt-2 mb-2 col-sm email">
                    <span className="input-group-text me-2" id="basic-addon1">Email</span>
                    <input type="text" id="email" name="email" className="w-75" onBlur={ (e) => { handleCadastro(e) }} placeholder="nfe@pneusbrasil.com.br" />
            </div>             
            </div> 
            <div className="col-12">
                <Button type="submit" className="btn btn-success" onClick={(e) => handlingenviar(e)}>Salvar</Button>
            </div>
            <Spinner isLoading={ShowLoading} />
        </Container>
        </>
    )
};

export default Clientes;