
import axios from 'axios';

//const CA = "-----BEGIN CERTIFICATE-----MIIDWzCCAkOgAwIBAgIJZGFtG/B2o3n1MA0GCSqGSIb3DQEBCwUAMBQxEjAQBgNVBAMTCWxvY2FsaG9zdDAeFw0yNDA4MTAxMzMwMDJaFw0yNDA5MDkxMzMwMDJaMBQxEjAQBgNVBAMTCWxvY2FsaG9zdDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMabQbno3CzQQemdngoU6ZlVGDtj36RWd4gfMOiaUjPrjSX0vjbZJ3ld3s0SGCMOzm1qZ+70xI9yFhg7UWF6CtY8YsHryHTMjf6z96LWiz04HK6FVM8XzmlWGifmfbu5JfbaIf4AH+hkITRfNlQUU0zXPHHddesZizVqWnp6B1YcoF9aJfuHIpj1fyEGLdNb/FD4RFzIns6bnzRx6OQTtaslK9HZ9ZG9n7UIHgTMP8wdBbN5btBigs8cxqS1ZGtaL+8Zv00ouZOQEajJSZt0/SrntV7dkZu6VTUf90x8XmEdOGDGc31btJ3X3d2+Mq/1wQNvkIrnx7V85TGtyz+EMCAwEAAaOBrzCBrDAMBgNVHRMEBTADAQH/MAsGA1UdDwQEAwIC9DAxBgNVHSUEKjAoBggrBgEFBQcDAQYIKwYBBQUHAwIGCCsGAQUFBwMDBggrBgEFBQcDCDBcBgNVHREEVTBTgglsb2NhbGhvc3SCFWxvY2FsaG9zdC5sb2NhbGRvbWFpboIGbHZoLm1lgggqLmx2aC5tZYIFWzo6MV2HBH8AAAGHEP6AAAAAAAAAAAAAAAAAAAEwDQYJKoZIhvcNAQELBQADggEBALT/LKrc6eaEnEZMni44KDn88Kp7R7LGnkl8eEAl5sFeo49mOPZr85CbTD+T81qMe+Odub52X9c9P1Hd0tKHhKFAD3Gfu1PKIhpsxGKntC+GGzBk01z6Q+t2Wigd7rEQ0d9Z8vMMqQw4b2oH1mZbcHZO4sWaZGOXXOYio4emA4RJzZpTtdQXNIgtH0ImYs0Nq2aWlaRbW4IcHDty9b1AjKHTlrU/ondmYCvXp9fyq1g0Fxv087AsVsg2hunheMWgxAmKjGNZ02eVaOD0nvT0oW5cld2idXa77lMdJnFRA3m64Cbelblr+RU2KIMtVPdNOBlyjw9yIqmWENva2nsX42k=-----END CERTIFICATE-----"
//const url = "https://grupopneusbrasil.ddns.net:8188"
//const HttpsAgent = new https.Agent({ keepAlive: true })
//   ca: CA
// });

const api = axios.create({
    // httpsAgent: HttpsAgent,
   // httpsAgent: options,
    //https port 8188
    //baseURL: 'https://177.185.131.134:8188/rest/',     
    baseURL: 'https://198.0.1.201:8188/rest/',

    //http port 8187
    //baseURL: 'http://grupopneusbrasil.ddns.net:8187/rest/'
    //baseURL: 'http://198.0.1.201:8187/rest/'
    headers: {
        "Content-type": "application/json",
        'Accept': 'application/vnd.github+json',
      }     
});
// api.defaults.httpsAgent = new (require('https')).Agent({
//     rejectUnauthorized: false, // Disable SSL verification (development only)
//   });
export default api;