//import React, { useContext, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './index.scss';
import Navbar from "../../Components/Navbar";
import Listaentradas from "./listaentradas"
import { useContext } from "react";
import { UserContext } from '../../context/UserContext';
const Entrada = () => {
    const {user} = useContext(UserContext)
    const navigate = useNavigate();      
    const novaentrada = () => {
        if(user.codigo !== '') {
            navigate('/novaentrada')
        }else{
            alert("Rotina disponivel somente para consultores!")
        }
    }       
    return(
    <div>
    <Navbar/>
    <Container>
        <div>
            <span>Consultor: {user.name}</span>
        </div>
        <Button onClick={() => novaentrada()} className="mt-2">Nova Entrada</Button>
        <Listaentradas/>
    </Container>
    </div>    
    )
};

export default Entrada;