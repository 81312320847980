import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { UserContext } from '../../context/UserContext';
import api from '../Api/Api';
import logo from "../../assets/logo.png";
import "./index.scss";
import Spinner from "../../Components/Spinner";
const Login = () => {
  const {user, setUser} = useContext(UserContext)
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')
  const [callback, setCallback] = useState<any>()
  const [ShowLoading, setShowLoading] = useState(false)
  const navigate = useNavigate();  
  function fetchLogin () {
    setTimeout(() => {
        setShowLoading(true)        
        let chave = '';
        //console.log(api.get('Logar?Usuario=' + usuario + '&Senha=' + password))     
        api.get('Logar?Usuario=' + usuario + '&Senha=' + password)        
        //axios.get('https://grupopneusbrasil.ddns.net:8188/rest/Logar?Usuario=' + usuario + '&Senha=' + password)
            .then(response => {
                chave = response.data.Usuarios[0]['token'];
                if (chave === 'PneusBrasilLogin2024-sim-liberado+o-Vendedor') {
                    setUser({
                        islogin: true,
                        name: response.data.Usuarios[0]['NomeVendedor'],
                        codigo: response.data.Usuarios[0]['Vendedor']
                    })
                        setShowLoading(false) 
                        setCallback(null)
                    } else {    
                        setShowLoading(false)              
                        return alert('Usuario ou senha invalido!');
                    }                 
                })
            .catch((error) => {
                setShowLoading(false)
                alert("Ocorreu um erro ao buscar as informações!");
                return setCallback(new Error('Tente novamente!'));
            });
    }, 10);   
  }
  useEffect(() =>{
  
        if (user.islogin){
            navigate('/home')
        }

  })
    
  return (
    <Container>
            <div className='logo'>
                <img
                    src={logo}
                    width="150"
                    className="logo-info"
                    alt="Heringer"
                />                
            </div> 
        <div id="formlogin">
            <h1>Login do Sistema</h1>           
            {/* <form name="loginForm"> */}

            <div className="form">
                
                <div className="field">
                    <label htmlFor="login">Usuario</label>                    
                    <input 
                        //value={'ismael'}
                        type="text" 
                        name="login" 
                        onChange={e => setUsuario(e.target.value)} 
                        placeholder="Digite o seu usuario" 
                    />
                </div>

                <div className="field">
                    <label htmlFor="password">Senha</label>
                    <input 
                    //value={'123'}
                    type="password" 
                    name="password" 
                    onChange={e => setPassword(e.target.value)} 
                    placeholder="Digite sua Senha" 
                    />
                </div>

                <div className="actions">
                <button className="primary" onClick={() => fetchLogin()}>Entrar</button>
                </div>
                
            </div>
            {/* { isLoginPending && <div>Por favor aguarde...</div> } */}
            {/* { isLoggedIn && <div>Success.</div> } */}
            {/* { loginError && <div>{loginError.message}</div> } */}
            {/* </form> */}
        </div>
        <Spinner isLoading={ShowLoading} />
    </Container>
  )
}


export default Login;