import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

//Tipando os dados que quero para usuário
type User = {
  islogin: boolean;
  name: string;
  codigo: string;
};

export interface UserContextInteface {
  user: User,
  setUser: Dispatch<SetStateAction<User>>
}

const defaultState = {
  user: {
    islogin: false,
    name: '',
    codigo: ''
  },
  setUser: (user: User) => {}
} as UserContextInteface

export const UserContext = createContext(defaultState)

type UserProviderProps = {
  children: ReactNode
}

export default function UserProvider({children}: UserProviderProps) {
  const [user, setUser] = useState<User>({
    islogin: false,
    name: '',
    codigo: ''
  });
  return (
    <UserContext.Provider value={{user,setUser}}>
        {children}
    </UserContext.Provider>
  )
}