import { Button, Container } from "react-bootstrap";
import Navigation from "../Navbar";
import { useEffect, useState } from "react";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import api from "../Api/Api";
const Veiculo = () => {
    const navigate = useNavigate(); 
    const [placa, setPlaca] = useState('')
    const [marca, setMarca] = useState('')
    const [modelo, setModelo] = useState('')
    const [cor, setCor] = useState('')
    const [motor, setMotor] = useState('')
    const [ano, setAno] = useState('')
    const [kmatual, setKmatual] = useState('')
    const [cadok, setCadok] = useState(false)
    const [ShowLoading, setShowLoading] = useState(false)
    const handleCadastro = (e: any) => {
        if(e.target.id === 'placa'){
            setPlaca(e.target.value)
        }else if (e.target.id === 'marca'){
            setMarca(e.target.value)
        }else if (e.target.id === 'modelo'){
            setModelo(e.target.value)
        }else if (e.target.id === 'cor'){
            setCor(e.target.value)
        }else if (e.target.id === 'motor'){
            setMotor(e.target.value)
        }else if (e.target.id === 'ano'){
            setAno(e.target.value)
        }else if (e.target.id === 'kmatual'){
            setKmatual(e.target.value)
        }
    };
    const handlingenviar = (e: any) =>{
        setShowLoading(true)
        if((placa && marca && modelo && cor && motor ) === ''){
            alert('Preencher os campos obrigatorios!')
            return
        }        
        const url = 'Veiculo';
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "User-Agente": "PneusBrasil",
            "Accept": "*/*",
          }         
        const data = {
            'Empresa':"01",
            'Filial':"01",
            'ZA_PLACA': placa,
            'ZA_MARCA': marca,
            'ZA_MODELO': modelo,
            'ZA_COR': cor,
            'ZA_MOTOR': motor,
            'ZA_ANO': ano,
            'ZA_KMATUAL': kmatual            
        }
        api.post(url,data, {"headers" : headers})
        .then(function (response) {
            //console.log(response.data['info']);
            if(response.data['info'] === 'ja existe'){
                setShowLoading(false)
                alert('Veiculo já esta cadastrado com essa placa! Placa: '+ response.data['codigo'])
            }  
            if(response.data['info'] === 'criado'){ 
                setShowLoading(false)
                alert('Veiculo cadastrado com sucesso, placa: '+ response.data['codigo'] )
                setCadok(true)               
            }                       
        })
        .catch(function (error) {
            console.error(error);
            setShowLoading(false)
            alert('Alert: Erro de conexão com servidor! ')
        });                        
    }
    useEffect(() => {
        if(cadok) { 
            navigate('/novaentrada')
        }
                       
    })    
    return(
        <>
            <Navigation />
            <Container>
                <div>
                    <span className="d-flex justify-content-center fs-3 text-primary">Cadastro de veículos</span>
                </div> 
                <div className="row"> 
                    <div className="input-group mt-2 mb-2 col" >
                        <span className="input-group-text me-2" id="basic-addon1">Placa</span><span className="me-2 text-danger">*</span>
                        <input type="text" id="placa" className="w-50 " onBlur={ (e) => { handleCadastro(e) }} placeholder="PBI1A25"/>
                    </div>                                         
                    <div className="input-group mt-2 mb-2 col-sm">
                        <span className="input-group-text me-2" id="basic-addon1">Marca</span><span className="me-2 text-danger">*</span>
                        <input type="text" id="marca" className="w-50 " onBlur={ (e) => { handleCadastro(e) }} placeholder="000002"/>
                    </div>   
                    <div className="input-group mt-2 mb-2 col-sm">
                        <span className="input-group-text me-2" id="basic-addon1">Modelo</span><span className="me-2 text-danger">*</span>
                        <input type="text" id="modelo" className="w-50" onBlur={ (e) => { handleCadastro(e) }}  placeholder="GOL"/>
                    </div>                         
                </div>    
                <div className="row"> 
                    <div className="input-group mt-2 mb-2 col" >
                        <span className="input-group-text me-2" id="basic-addon1">Cor....</span><span className="me-2 text-danger">*</span>
                        <input type="text" id="cor" className="w-50 " onBlur={ (e) => { handleCadastro(e) }} placeholder="BRANCA"/>
                    </div>                                         
                    <div className="input-group mt-2 mb-2 col-sm">
                        <span className="input-group-text me-2" id="basic-addon1">Motor</span><span className="me-2 text-danger">*</span>
                        <input type="text" id="motor" className="w-50 " onBlur={ (e) => { handleCadastro(e) }} placeholder="1.0"/>
                    </div>   
                    <div className="input-group mt-2 mb-2 col-sm">
                        <span className="input-group-text me-2" id="basic-addon1">Ano ......:</span><span className="me-2 text-success">*</span>
                        <input type="text" id="ano" className="w-50" onBlur={ (e) => { handleCadastro(e) }}  placeholder="2024"/>
                    </div>                         
                </div>  
                <div className="input-group mt-2 mb-2">
                    <span className="input-group-text me-2" id="basic-addon1">KM Atual</span>
                    <input type="text" id="kmatual" className="w-25" onBlur={ (e) => { handleCadastro(e) }}  placeholder="1000"/>
                </div>  
                <div className="col-12">
                    <Button type="submit" className="btn btn-success" onClick={(e) => handlingenviar(e)}>Salvar</Button>
                </div>
            <Spinner isLoading={ShowLoading} />                                                                                        
            </Container>
        </>
    )
};

export default Veiculo;